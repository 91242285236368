import React, { useEffect, useState } from 'react';
import { useTranslation } from '@elevio/kb-kit/lib/hooks';
import PageLayout from '../components/layouts/Page';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WithTitle from '@elevio/kb-kit/lib/componentsInternal/WithTitle';
import { getIDPToken } from '../requests/idp-access-token';
import { getSSOConfig } from '../requests/get-sso-config';
import { getTokenFromLocalStorage, setTokenInLocalStorage } from '../utils/local-storage';
import { generateUniqueId, randomCodeGenerator } from '../utils/util';
import { getParameterByName, removeParamByName } from '../utils/url-helper';
import Loader from '../components/Loader';
function Page() {
    const { t } = useTranslation();
    const [lang, setLang] = useState('en');
    const [accessToken, setAccessToken] = useState('');
    const [bHLoginUrl, setBHLoginUrl] = useState('');
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const redirectState = urlSearchParams.get('state');
    const BHState = redirectState === null || redirectState === void 0 ? void 0 : redirectState.split("-");
    const [anonymousId, setAnonymousId] = useState((BHState === null || BHState === void 0 ? void 0 : BHState[0]) || randomCodeGenerator(300));
    const [SID, setSID] = useState((BHState === null || BHState === void 0 ? void 0 : BHState[1]) || generateUniqueId('sid'));
    const getBeverlyHillsToken = () => {
        getSSOConfig().then((ssoConfigResponse) => {
            const existingToken = getTokenFromLocalStorage();
            if (existingToken) {
                setAccessToken(existingToken);
            }
            else {
                const urlSearchParams = new URLSearchParams(window.location.search);
                const beverlyHillAuthCode = urlSearchParams.get('code');
                if (beverlyHillAuthCode) {
                    getIDPToken(beverlyHillAuthCode).then((idpTokenResponse) => {
                        setTokenInLocalStorage(idpTokenResponse.token);
                        setAccessToken(idpTokenResponse.token);
                        window.location.href = removeParamByName('code', window.location.href);
                        setIsLoggingIn(false);
                    });
                }
                else {
                    let redirectURL;
                    if (getParameterByName('themeVersion')) {
                        redirectURL = `${window.location.origin}/en/submitRequest?themeVersion=${getParameterByName('themeVersion')}&themeId=${getParameterByName('themeId')}`;
                    }
                    else {
                        redirectURL = `${window.location.origin}/en/submitRequest`;
                    }
                    const { ssoBaseUrl: beverlyHillsBaseURL, clientAppId: beverlyHillsClientId, } = ssoConfigResponse;
                    let beverlyHillsAuthURL;
                    const urlSearchParamsData = new URLSearchParams(window.location.search);
                    const redirectError = urlSearchParamsData.get('error');
                    if (redirectError === 'login_required') {
                        window.location.href = `${beverlyHillsBaseURL}/authorize?client_id=${beverlyHillsClientId}&scope=openid+profile+email+phone+address&response_type=code&sid=${SID}&redirect_uri=${encodeURIComponent(redirectURL)}&state=${anonymousId}-${SID}`;
                    }
                    beverlyHillsAuthURL = `${beverlyHillsBaseURL}/authorize?client_id=${beverlyHillsClientId}&scope=openid+profile+email+phone+address&response_type=code&sid=${SID}&redirect_uri=${encodeURIComponent(redirectURL)}&state=${anonymousId}-${SID}&prompt=none`;
                    setBHLoginUrl(beverlyHillsAuthURL);
                }
            }
        }).catch(err => {
            console.error(err);
        });
    };
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        if (urlSearchParams.get('code')) {
            setIsLoggingIn(true);
        }
        getBeverlyHillsToken();
        const interval = window.setInterval(function () {
            if (document.cookie) {
                let selectedLang = check_cookie_name('googtrans');
                if (lang !== selectedLang) {
                    setLang(selectedLang);
                }
            }
        }, 1000);
        return () => {
            clearInterval(interval);
            window.location.href = removeParamByName('state', window.location.href);
        };
    }, []);
    let url = new URL(process.env.PRIVATE_SUBMISSION_URL);
    function check_cookie_name(name) {
        var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) {
            return match[2];
        }
        else {
            return '/en/en';
        }
    }
    return (<PageLayout className="hide-submit-request" header={<Header />} footer={<Footer />}>
      {isLoggingIn &&
            <Loader className="logging-in" text={`Logging in...`}></Loader>}
      <WithTitle title="Make a Request" children></WithTitle>
      {!accessToken && <a href={bHLoginUrl}>Click here to login</a>}
      <div className="request-page">
        <iframe id='crm-issue-iframe' src={`${process.env.PRIVATE_SUBMISSION_URL}?translate=${lang}&token=${accessToken}&isIFrame&anonymousId=${anonymousId}`} allow={`geolocation ${url.origin}`}/>
      </div>
    </PageLayout>);
}
export default Page;
